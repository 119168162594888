export const environment = {
    production: false,
    apiUrl: 'https://trandth:M3oHJ%404@stage.api.trandth.com/api',
    googleMapApiKey: 'AIzaSyByyEvu6xsuYwKMo0jHFzhC_P1z7STU3Ts',
    googleClientId:
        '1035223119163-k4an1dqbh0v4qp01cg014qr09vog753h.apps.googleusercontent.com',
    googleClientSecret: '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',
    googleCalendarApiKey: 'AIzaSyDjTjUaAXg_EMl2frCQ-T853Q70bCHtVp0',
    appSettings: {
        hideConsoleErrors: true,
    },
};
